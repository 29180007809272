export default theme => ({
  header: {
    display: "flex",
    padding: "0px 10px 0px 10px",
    height: "50px",
    backgroundColor: "#ffffff",
    boxShadow: "0px -1px 5px -1px #1f1f1f",
  },
  toolbar: {
    alignSelf: "center",
    width: "1085px",
    minHeight: "50px",
    "@media (max-width:1150px)": {
      width: "100%",
      padding: "0px",
    },
  },
  gridContainer: {},
  headerLogo: {
    marginTop: "7px",
    height: "44px",
    cursor: "pointer",
  },
  
  headerRightMenuRow: {
    float: "right",
    marginTop: "9px",
    '& a': {
      textDecoration: "none",
    },
    '& button': {
      textTransform: "none",
      border: "none",
      borderRadius: "5px",
      outline: "none",
      padding: "7px",
      fontSize: "17px",
      fontFamily: "Montserrat Regular",
      backgroundColor: "white",
      color: "#646464",
      cursor: "pointer",
      "@media (max-width:550px)": {
        fontSize: "15px",
        padding: "5px",
        marginTop: "2px",
      },
    },  
    '& button:hover': {
      backgroundColor: "#EEE",
      color: "#454545"
    }
  },
  getFeaturedButtonContainer: {
    marginLeft: "7px",
    textDecoration: "none",
    '& button': {
      borderRadius: "50px",
      border: "1px solid #669900",
      backgroundColor: "#669900",
      color: "#FFF",
    },  
    '& button:hover': {
      color: "#5F802F",
      backgroundColor: "#FFF",
      border: "1px solid #5F802F",
      transitionDuration: "0.1s",
    },
  },
})