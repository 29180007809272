export default theme => ({
  container: {
    position: "relative",  
    zIndex: 10,
  },
  backgroundImage: {
    position: "absolute",
    backgroundPosition: "center top", 
    zIndex: -1,
    width: "100%",
    height: "650px", 
    transition: "height 0.2s",
    '&.backgroundImageShrinked': {
      height: "360px", 
    }
  },
  jobBoardTitle: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    padding: "30px 10px 20px 10px",
    color: "white",
    fontSize: '20px',
    "@media (max-width:800px)": {
      fontSize: "18px",
    },   
    "@media (max-width:600px)": {
      fontSize: "14px",
    },
    '& h1': {
      marginBottom: "0px",  
    },
    '& h3': {
      marginTop: "10px",  
      fontWeight: "100",
    },
    '& p': {
      fontSize: "18px",
      marginTop: "20px",
      marginBottom: "10px",
    },
  },
  search: {
    position: "relative",
    alignSelf: "center",
    width: "500px",
    height: "47px",
    borderRadius: "50px",
    border: 0,
    marginTop: "25px",
    outline: "none",
    padding: "0px 10px 0px 20px",
    "@media (max-width:800px)": {
      width: "400px",
    },   
    "@media (max-width:500px)": {
      width: "70%",
    },
  },
  searchInput: {
    float: "left",
    paddingLeft: "30px",
    fontFamily: "Montserrat Regular",
    width: "88%",
    marginTop: "10px",
    "@media (max-width:800px)": {
      width: "80%",
    },   
    "@media (max-width:500px)": {
      width: "70%",
      fontSize: "14px",
    },
  },
  searchIconButton: {
    position: "absolute",
    left: 0,
    color: "#BBB",  
    margin: "13px 0px 0px 15px",
  },
  discoverButton: {
    alignSelf: "center",
    margin: "3px 0px 3px 0px",
    height: "40px",
    display: "block",
    width: "240px",
    backgroundColor: "#669900",
    border:"none",
    borderRadius: "100px",
    fontSize: "18px",
    fontFamily: "Montserrat Regular",
    color: "white",
    outline: "none",
    cursor: "pointer",
    '&:hover': {
      width: "245px",
      height: "46px",
      margin: "0px",
      color: "#5F802F",
      border: "1px solid #5F802F",
      backgroundColor: "#FFF",
      transitionDuration: "0.1s",
    },
  },

  valuesContainer: {
    display: "flex",
    justifyContent: "center",
  },
  valuesContainerHidden: {
    display: "none",
    justifyContent: "center",
  },
  valuesColumns: {
    position: "relative",
    margin: "0px 10px 0px 10px",
    width: "1000px",
    backgroundColor: "white",
    borderRadius: "3px",
    padding: "10px 50px 20px 50px",
    boxShadow: "0px 0px 36px 1px #b5b5b5",   
    columnCount: 4,
    "@media (max-width:1000px)": {
      columnCount: 2,
      width: "620px",
    },
    "@media (max-width:600px)": {
      columnCount: 1,
      width: "250px", 
    }, 
  },
  values: {
    display: "block",
    breakInside: "avoid",
    fontSize: "14px",
    padding: "10px",
    color: "rgb(50, 89, 236)",
    fontWeight: "bold",
    maxHeight: "100%",
  },
  valueSelected: {
    color: "white !important",
    backgroundColor: "inherit",
  },
  categoryTitleContainer: {
    marginLeft: "5px",
  },
  categoryTitle: {
    lineHeight: "1.4",
    fontSize: "14px",
    color: "#3e3f3f",
    borderBottom: "2px solid #e6e6e6",    
  },
  value: {
    display: "inline-block",
    padding: "5px",
    marginBottom: "2px",
    border: "1px solid transparent",
    borderRadius: "5px",
  },
  companyCardContainer: {
    margin: "0 auto",
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gridGap: "30px",
    padding: "50px 10px 96px 10px",
    maxWidth: "1100px",
    "@media (max-width:1000px)": {
      maxWidth: "720px",
      gridTemplateColumns: "repeat(2,1fr)",
      padding: "60px 10px 60px 10px",
    },
    "@media (max-width:720px)": {
      maxWidth: "350px", 
      gridTemplateColumns: "repeat(1,1fr)",
      padding: "60px 10px 60px 10px",
    },
  },
  searchNoResultContainer: {
    display: "grid",
    height: "55vh",
  },
  searchNoResult: {
    margin: "180px 0px 80px 0px",
    alignSelf: "center",
    textAlign: "center",
  },
  loaderContainer: {
    marginTop: "100px",
  },
  paginationContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    '& button:hover': {
      backgroundColor: "rgba(82, 82, 82, 0.7)",
    },
  },
});

