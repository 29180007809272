import React, { Component } from 'react';

import InfoComponent from 'components/InfoComponent/infoComponent';
import {getTermsOfUse} from "utils/jobSearchService";

class TermsOfUsePage extends Component {
  state = {  
    termsOfUseData: {},
  }

  componentDidMount() {
    this.getData();  
  };

  getData = () => {
    getTermsOfUse().then(response => {
      this.setState({ termsOfUseData: response.data.terms_of_use[0] })
    })
  };

  render() { 
    const { termsOfUseData } = this.state;
    return (
      <div>
        <InfoComponent data={termsOfUseData} />
      </div>
    );
  }
}
 
export default TermsOfUsePage;