import React, { Component } from 'react';

import InfoComponent from 'components/InfoComponent/infoComponent';
import {getPrivacyPolicy} from 'utils/jobSearchService';

class PrivacyPolicyPage extends Component {
  state = {  
    privacyPolicyData: {},
  }

  componentDidMount() {
    this.getData();  
  };

  getData = () => {
    getPrivacyPolicy().then(response => {
      this.setState({ privacyPolicyData: response.data.privacy[0] })
    })
  };

  render() { 
    const { privacyPolicyData } = this.state;
    return (  
      <div>
        <InfoComponent data={privacyPolicyData} />
      </div>
    );
  }
}
 
export default PrivacyPolicyPage;