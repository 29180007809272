import React, { Component } from 'react';

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import styles from "./sticker.styles";

class Sticker extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  scrollToRef = (event, ref) => {
    window.scrollTo({left: 0, top: ref.current.offsetTop, behavior: "smooth"})
  }

  render() { 
    const { classes, inside, selected, data, myScrollRef, isFiltered } = this.props;
    if (inside){
      return(
        <div>
          {(selected.length <= 0 || !isFiltered) ?
            <div className={`${classes.circle} ${classes.circleInside}`}>
              <div>select<br/>categories</div>
            </div>
            :
            <div 
              className={`${classes.circle} ${classes.circleInside}`}
              onClick={event => this.scrollToRef(event, myScrollRef)}
            >  
              <div className={classes.circleNumber}>{data.teams && data.teams.count}</div>
              <div className={classes.circleText}>matches</div>
            </div> 
          }
        </div>
      );
    }
    return(
      <div>
        {(selected.length <= 0 || !isFiltered) ?
          <div className={`${classes.circle} ${classes.circleOutside}`}>
            <div>select<br/>values</div>
          </div>
          :
          <div 
            className={`${classes.circle} ${classes.circleOutside}`} 
            onClick={event => this.scrollToRef(event, myScrollRef)}
          >
            <div className={classes.circleNumber}>{data.teams && data.teams.count}</div>
            <div className={classes.circleText}>matches</div>         
          </div> 
        }
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(Sticker));