export default theme => ({
  companyCard: {
    cursor: "pointer",
    display: "grid",
    borderRadius: "5px",
    height: "370px",
  }, 
  companyCardImageContainer: {
    borderRadius: "5px",
    position: "relative",
    overflow: "hidden",  
    '& img': {
      height: "370px",
      minWidth: "100%",
      marginLeft: "50%",
      transform: "translateX(-50%)",
    },
    backgroundSize: "cover",
    objectFit: "cover",
  },
  companyCardFooterContent: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    zIndex: 10,
    top: "230px",
    height: "140px",
    minWidth: "100%",
    padding: "0px 10px 5px 10px",
    background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.15) 15%, rgba(0,0,0,0.3) 28%, rgba(0,0,0,0.8) 100%)",
    fontFamily: "Montserrat Medium",
    letterSpacing: "0.6px",
    color: "#FFF",
    fontWeight: "bold",
    '& span': {
      position: "bottom",
      height: "36px",
      margin: "8px 15px 8px 0px",
      fontSize: "14px",
    },
    '& h2': {
      position: "bottom",
      margin: "10px 18px 0px 0px",
      color: "#FFF",
      fontSize: "19px",
      letterSpacing: "1px",
    },
  },
});