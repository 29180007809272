export default theme => ({
  root: {
    display: "flex", 
    flexDirection: "column",
    margin: 0,
    fontFamily: "Montserrat Regular",
    backgroundColor: "#f6f6f6",
  },
  wrapper: {
    position: "relative",
  },
  mainContent: {
    minHeight: "95vh",
  },
});