import React, { Component } from 'react';
import JobPageMainContent from "./components/jobPageMainContent";
import {getTeam} from "utils/jobSearchService"

class JobPage extends Component {
  constructor(props){
    super(props);
    this.state = {  
      teamData: {},
    }
  }

  componentDidMount() {
    this.loadData();
  };

  loadData() {
    getTeam(this.props.match.params.slug).then(response => {
      this.setState({teamData: response.data.team[0]})
    }).catch((err) => {
      this.props.history.goBack();
    })
  };

  render() { 
    const { teamData } = this.state;
    return (  
      <div>
        <JobPageMainContent 
          teamData={teamData}
        />         
      </div>
    );
  }
}
 
export default JobPage;