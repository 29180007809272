export default theme => ({
  footer: {
    borderTop: "1px solid #669900",
    width: "100%",
  },
  upperFooter: {
    display: "flex",
    justifyContent: "center",
    padding: "15px",
    backgroundColor: "#669900",
  },
  upperFooterContent: {
    display: "flex",
    flexDirection: "row",
    width: "1100px",
    paddingBottom: "20px",
    '& h4': {
      color: "#FFF",
    },
    "@media (max-width:1000px)": {
      flexDirection: "column",
      width: "700px",
    },
    "@media (max-width:720px)": {
      flexDirection: "column",
      width: "350px",
    },
  },
  leftSide: {
    width: "72%",
    display: "flex",
    flexDirection: "row",
    "@media (max-width:1000px)": {
      width: "100%",
    },
    "@media (max-width:720px)": {
      flexDirection: "column",
      width: "100%",
    },
  },
  upperFooterAboutUs: {
    width: "50%",
    '& p': {
      padding: "0px",
      fontSize: "14px",
      color: "#FFF",
    },
    "@media (max-width:720px)": {
      width: "100%",
    },
  },
  upperFooterSubscribeForm: {
    margin: "0px 0px 0px 60px",
    width: "50%",
    "@media (max-width:720px)": {
      width: "100%",
      margin: "0px 0px 10px 0px",
    },
    '& p': {
      fontWeight: "bold",
      padding: "0px",
      fontSize: "14px",
      color: "#FFF",
    },
  },
  form: {
    padding: "0px !important",
  },
  emailLabel: {
    fontSize: "16px",
    lineHeight: "16px",
    color: "#EEE",
  },
  inputEmail: {
    zIndex: 10,
    marginTop: "6px",
    padding: "6px",
    width: "80%",
    borderRadius: "50px !important",
    outline: "none",
    '&:focus': {
    }
  },
  inputButton: {
    margin: "16px 0px 0px 0px !important",
    padding: "8px 10px 8px 10px",
    borderRadius: "50px !important",
    outline: "none",
    fontFamily: "Montserrat Regular",
    fontSize: "15px",
    color: "#404040",
    cursor: "pointer",
    '&:hover': {
      backgroundColor: "#D0D0D0",
      border: "1px solid #909090 !important",
    }
  },
  upperFooterSocial: {
    '& a': {
      color: "#FFF",
      cursor: "pointer",
      textDecoration: "none",
      '&:hover': {
        textDecoration: "underline",
      },
    },
    '& li': {
      marginBottom: "15px",
      marginLeft: "-7px",
      color: "#FFF",
      listStyleType: "none",
      textDecoration: "none",
    },
    '& svg': {
      width: "30px !important",
    },
  },
  lowerFooter: {
    padding: "15px",
    backgroundColor: "#4B7100",
    overflow: "hidden",
  },
  footerContent: {
    margin: "20px 0px 20px 0px",
  },
  footerText: {
    display: "block",
    textAlign: "center",
    fontSize: "14px",
    lineHeight: "22px",
    fontFamily: "Montserrat Regular",
    color: "white",
    "@media (max-width:520px)": {
      fontSize: "12px",
    },
    '& p': {
      margin: "0px",
    },
    '& a': {
      color: "white",
    },
    '& a:hover': {
      fontWeight: "bold",
    },
  },
  heartIcon: {
    fontSize: "14px",
  },
});

