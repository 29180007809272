import React from "react";
import { withRouter } from "react-router-dom";
import { Toolbar, AppBar, Grid } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import Logo from "images/birchlife_header.png";
import styles from "./styles";

class MainHeader extends React.Component {
  nextPath(path) {
    if (this.props.location.pathname === "/" && path === "/")
      window.location.reload(false);
    else if (this.props.location.pathname === "/about" && path === "/about")
      window.location.reload(false);
    else this.props.history.push(path);
  }
  render() {
    const { classes } = this.props;
    return (
      <div>
        <AppBar className={classes.header} position="relative">
          <Toolbar className={classes.toolbar}>
            <Grid className={classes.gridContainer} container>
              <Grid item xs={4}>
                <img
                  src={Logo}
                  alt="birchlife company logo"
                  className={classes.headerLogo}
                  onClick={() => this.nextPath(`/`)}
                />
              </Grid>
              <Grid item xs={8}>
                <div className={classes.headerRightMenuRow}>
                  <button onClick={() => this.nextPath(`/about`)}>About</button>
                  <a
                    className={classes.getFeaturedButtonContainer}
                    href="https://docs.google.com/forms/d/e/1FAIpQLSeuBjr5t4lzrKLNmVnHbm35n0I7maN_DPxV9n0D2h58rXFGQg/viewform"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button>Get Featured</button>
                  </a>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MainHeader));
