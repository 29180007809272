import React, { Component } from 'react';

import InfoComponent from 'components/InfoComponent/infoComponent';
import {getAbout} from "utils/jobSearchService";

class AboutPage extends Component {
  state = {
    aboutData: {},
  };

  componentDidMount() {
    this.getData();  
  };

  getData = () => {
    getAbout().then(response => {
      this.setState({ aboutData: response.data.about_us[0] })
    })
  };

  render() {
    const { aboutData } = this.state;
    return (
      <div>
        <InfoComponent data={aboutData} />
      </div>
    );
  }
}
 
export default AboutPage;