export default theme => ({
  circle: {
    display: "inline",
    cursor: "pointer",  
    right: 0,
    width: "100px",
    height: "100px",
    textAlign: "center",
    '& div':{
      transform: "translate(0%, 80%)",
      fontWeight: "bold",
      color: "white",
    },
  },
  circleInside: { 
    position: "absolute",
    bottom: 0,
    marginBottom: "10px",
    marginRight: "-50px",
    borderRadius: "50%",
    background: "#ff3399",
  },
  circleOutside: {
    position: "fixed",
    zIndex: 100,
    bottom: "40px",
    alignSelf: "flex-end",
    marginBottom: "40px",
    borderRadius: "50% 0% 0% 50%",
    backgroundColor: "rgba(255, 51, 153, 0.9)",  
  },
  circleNumber: {
    marginTop: "-8px",
    fontSize: "30px",
    color: "white",
    padding: 0,
  },
  circleText: {
    margin: 0,
    position: "absolute",
    width: "100%",
    bottom: "40px",
  },
});