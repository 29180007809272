import { instance } from "utils/dataService";
import axios from "axios";

let cancel;

function getFooter() {
  return instance.get(`api/v2/footer/`);
}

function getJobBoard() {
  return instance.get(`/api/v2/businessboard/`);
}

function getValueTagCategories() {
  return instance.get(`/api/v2/business-value-categories/`);
}

function getValueTags() {
  return instance.get(`/api/v2/business-values/`);
}

function getTeamsFiltered(data, params = {}) {
  const CancelToken = axios.CancelToken;
  if (cancel){
    cancel()
  }
  return instance.get(`/api/v2/businesses/${data}`, {
    params : params,
    cancelToken: new CancelToken(function executor(c) {
      cancel = c
    })
  })
}

function getTeam(slug) {
  return instance.get(`/api/v2/businesses/${slug}/`)
}

function getAbout() {
  return instance.get(`api/v2/about/`);
}

function getPrivacyPolicy() {
  return instance.get(`api/v2/privacy/`);
}

function getTermsOfUse() {
  return instance.get(`api/v2/terms-of-use/`);
}

export {
  getFooter,
  getJobBoard,
  getValueTagCategories,
  getValueTags,
  getTeamsFiltered,
  getTeam,
  getAbout,
  getPrivacyPolicy,
  getTermsOfUse,
};