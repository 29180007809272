import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import PageLoader from "components/PageLoader/pageLoader";
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { splitIconString } from 'utils/functions';
import clsx from 'clsx';

import styles from "./jobPage.styles";

class JobPageMainContent extends Component {
  displaySidebarContent = (title, content) => {
    const { classes } = this.props;
    return (
      content &&
      content.trim().length !== 0 && (
        <div>
          <h4 className={classes.bodyTitle}>{title.toUpperCase()}</h4>
          <div className={classes.sidebarContent}>{parse(content)}</div>
        </div>
      )
    );
  };

  onClickCategory = (value) => {
    localStorage.setItem(
      "selectedValuesLocalStorage",
      JSON.stringify([
        {
          id: value.business_value__id,
          description: value.business_description,
          name: value.business_value__name,
          slug: value.business_value__slug,
        },
      ])
    );
    this.props.history.push(`/`);
  };

  displaySidebarValues = (section) => {
    const { classes, teamData } = this.props;
    return (
      teamData.business_values.length > 0 && (
        <div>
          <h4 className={classes.bodyTitle}>{section.toUpperCase()}</h4>
          <div className={classes.sidebarContent}>
            {!!teamData.business_values &&
              teamData.business_values.map((value) => (
                <p
                  key={value.business_value__id}
                  onClick={() => this.onClickCategory(value)}
                  className={classes.categoryLink}
                >
                  {value.business_value__name}
                </p>
              ))}
          </div>
        </div>
      )
    );
  };

  convertNodeToElement = (domNode) => {
    const { classes } = this.props;

    if (!!domNode.name && domNode.name === "iframe") {
      return (
        <div className={classes.responsiveIframeContainer}>
          <iframe src={domNode.attribs.src} allowFullScreen={true}></iframe>
        </div>
      );
    }
  };

  ellipsizeText = (text, length) => {
    if (text.length > length) text = text.substr(0, length) + "...";
    return text;
  };

  render() { 
    const { classes, teamData } = this.props;
    return Object.keys(teamData).length !== 0 ? (
      <div className={classes.container}>
        <div className={classes.heroContainer}>
          {!!teamData.logo && !!teamData.hero && (
            <div
              className={classes.heroPicture}
              style={{ backgroundImage: `url("${teamData.hero.file}")` }}
            />
          )}
          <div className={classes.heroInfoContainer}>
            <div className={classes.heroInfo}>
              <div className={classes.heroInfoDescription}>
                <h1 className={classes.companyTitle}>{teamData.title}</h1>
                <h4 className={classes.companySubtitle}>{teamData.tagline}</h4>
                <div className={classes.companyDescription}>
                  {parse(String(teamData.business_description))}
                </div>
              </div>
              <div className={classes.heroInfoSocial}>
                {!!teamData.links && 
                  teamData.links.map((item, index) =>
                  <a 
                    key={index} 
                    title={item.title != null ? item.title : item.channel[0].name} 
                    href={item.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className={clsx({
                      [classes.bold]: item.channel[0].icon === 'fas fa-home',
                    })}
                  >
                    <div className={classes.socialIcon}>
                      <FontAwesomeIcon 
                        icon={[splitIconString(item.channel[0].icon, 0), splitIconString(item.channel[0].icon, 1)]} 
                      />
                    </div>
                    {this.ellipsizeText(item.title != null ? item.title : item.channel[0].name, 24)}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.bodyContainer}>
          <div className={classes.body}>
            <div className={classes.contentContainer}>
              <div className={classes.mainContentContainer}>
                <h4 className={classes.bodyTitle}>OUR STORY</h4>
                <div className={classes.mainContent}>
                  {!!teamData.business_values &&
                    teamData.business_values.map((value, index) => (
                      <div key={index} className={classes.contentSection}>
                        <h3>{value.business_value__name.toUpperCase()}</h3>
                        <h5>{value.tagline}</h5>
                        {parse(String(value.article), {
                          replace: (domNode) => {
                            return this.convertNodeToElement(domNode);
                          },
                        })}
                      </div>
                    ))}
                </div>
              </div>
              <div className={classes.sidebarContentContainer}>
                {this.displaySidebarValues("categories")}
                {this.displaySidebarContent("address", teamData.address)}
                {this.displaySidebarContent("hours", teamData.hours)}
                {this.displaySidebarContent(
                  "contact information",
                  teamData.contact_information
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className={classes.loaderContainer}>
        <PageLoader />
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(JobPageMainContent));
