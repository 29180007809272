import React, { Component } from 'react';

class WindowWidthSticker extends Component {
    
  constructor(props){
    super(props);
    this.state = { 
      windowWidth: window.innerWidth, 
    }
  }

  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount() {
    window.addEventListener("resize", this.handleResize);
  }

  render() { 
    const { windowWidth } = this.state;
    const { children } = this.props;
    if (windowWidth > 1220 && children.props.inside === true){
      return ( <div>{children}</div> );
    }
    else if (windowWidth <= 1220 && children.props.inside === false){
      return ( <div>{children}</div> );
    }
    else{
      return ( <div></div> );
    }
  }
}
 
export default WindowWidthSticker;