import React from 'react';
import {
  Switch,
  Route,
  BrowserRouter as Router
} from "react-router-dom";

import Home from "containers/Home/home";
import ScrollToTop from "components/scrollToTop";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fab, fas)

class App extends React.Component {
  render() {
    return (
      <Router>
        <ScrollToTop />  
        <Switch>
          <Route path={"/"} component={Home}/>
        </Switch>
      </Router>
    );
  }
}

export default App;
