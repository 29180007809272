function splitIconString(icon, part) {
  const seperated = icon.split(' ');
  if (part === 0) {
    return seperated[0];
  }
  else {
    const seperatedAgain = seperated[1].substr(seperated[1].indexOf('-')+1);
    return seperatedAgain;
  }
} 

export {
  splitIconString
};