import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import styles from "./styles"; 

class PageLoader extends Component {
  render() { 
    const { classes } = this.props;

    return ( 
      <CircularProgress className={classes.loader}/>
    );
  }
}
 
export default withRouter(withStyles(styles)(PageLoader));