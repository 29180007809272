import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";

import { getFooter } from "utils/jobSearchService";
import styles from "./styles";
import { splitIconString } from "utils/functions";

class MainFooter extends Component {
  state = {
    footerData: {},
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    getFooter().then((response) => {
      this.setState({ footerData: response.data });
    });
  };

  render() {
    const { classes } = this.props;
    const { footerData } = this.state;
    return (
      <div className={classes.footer}>
        <div className={classes.upperFooter}>
          <div className={classes.upperFooterContent}>
            <div className={classes.leftSide}>
              <div className={classes.upperFooterAboutUs}>
                <h4>About Us</h4>
                {footerData?.footer?.about_us && parse(footerData?.footer?.about_us)}

              </div>
              <div className={classes.upperFooterSubscribeForm}>
                <h4>Subscribe</h4>
                <p>Receive updates, access specials, and more</p>
                <link
                  href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
                  rel="stylesheet"
                  type="text/css"
                />
                <div id="mc_embed_signup">
                  <form
                    action="https://birchlife.us2.list-manage.com/subscribe/post?u=f28b2d956d45af809fc661552&amp;id=90561887d8"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className={classes.form}
                    target="_blank"
                    noValidate
                  >
                    <div id="mc_embed_signup_scroll">
                      <div>
                        <label
                          htmlFor="mce-EMAIL"
                          className={classes.emailLabel}
                        >
                          Email Address <span>*</span>
                        </label>
                        <input
                          type="email"
                          name="EMAIL"
                          className={classes.inputEmail}
                          id="mce-EMAIL"
                        />
                      </div>
                      <div id="mce-responses">
                        <div id="mce-error-response"></div>
                        <div id="mce-success-response"></div>
                      </div>
                      <div>
                        <input
                          type="submit"
                          value="Subscribe"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          className={classes.inputButton}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <script
                  type="text/javascript"
                  src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
                ></script>
              </div>
            </div>
            <div className={classes.rightSide}>
              <div className={classes.upperFooterSocial}>
                <h4>Contact us</h4>
                {footerData?.footer?.footer_links.map((item, index) => 
                  <li key={index}>
                    <a
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={[splitIconString(item.channel[0].icon, 0), splitIconString(item.channel[0].icon, 1)]} />
                      {item.title}
                    </a>
                  </li>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.lowerFooter}>
          <div className={classes.footerContent}>
            {!!footerData.footer && (
              <span className={classes.footerText}>
                {parse(footerData.footer.footer)}
              </span>
            )}            
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(MainFooter));
