export default theme => ({
  container: {
    '& p':{
      margin: "0px 0px 15px 0px",
      fontSize: "15px",
      lineHeight: "1.6",
      color: "#5e5f5f",
    }, 
    '& a:link':{
      color: "#0066cc",
      textDecoration: "underline semi-bold",
    },
    '& a:visited':{
      color: "#0066cc",
      textDecoration: "underline semi-bold",
    },
    '& a:hover': {
      color: "#ff3399",
      textDecoration: "underline",
    },
    '& a:active': {
      color: "#F06",
      textDecoration: "underline",
    },
    '& ul': {
      fontSize: "15px",
      lineHeight: "1.6",
      color: "#5e5f5f",
    },
    '& li': {
      marginBottom: "15px",
    },
  },
  heroContainer: {
  },
  heroPicture: {
    position: "relative",
    backgroundPosition: "center center", 
    backgroundRepeat: "no-repeat",
    height: "260px",
    objectFit: "cover",
    backgroundSize: "cover",
  },
  roundLogo: {
    position: "absolute",
    right: 0,
    left: 0,
    bottom: 0,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "-55px",
    width: "100px",
    height: "100px",
    overflow: "hidden", 
    borderRadius: "50%",
    '& img': {
      position: "absolute",
      top: "-9999px",
      bottom: "-9999px",
      right: "-9999px",
      left: "-9999px",
      margin: "auto", 
    },  
  },
  heroInfoContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "white", 
    padding: "55px 20px 25px 20px",
  },
  heroInfo: {
    display: "flex",
    flexDirection: "row",
    width: "1000px",
    color: "#3e3f3f",
    "@media (max-width:1040px)": {
      width: "800px",
      flexDirection: "column",
    },
  },
  heroInfoDescription: {
    width: "750px",
    marginRight: "20px",
    "@media (max-width:1040px)": {
      width: "100%",
    },
  },
  companyTitle: {
    marginBottom: "15px",
  },
  companyDescription: {
  },
  heroInfoSocial: {
    display: "flex",
    flexDirection: "column",
    color: "#6f6f6f", 
    fontSize: "14px",
    lineHeight: 1.6,
    '& a':{
      marginBottom:"13px",
    },  
    "@media (max-width:1040px)": {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    "@media (max-width:600px)": {
      flexDirection: "column",
      '& a':{
        marginBottom:"10px",
      }, 
    },
  },
  socialIcon: {
    display: "inline-block",
    fontSize: "16px",
    width: "25px",
    
  },
  bodyContainer: {
    display: "flex",
    justifyContent: "center",
    padding: "0px 20px 0px 20px",
    "@media (max-width:600px)": {
      padding: "0px 10px 0px 10px",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    padding: "35px 0px 55px 0px",
    width: "1000px",
    "@media (max-width:1040px)": {
      width: "800px",
    },
    "@media (max-width:850px)": {
      width: "100%",
    },
  },
  bodyTitle: {
    color: "#4f4f4f", 
    fontWeight: "bold",
    fontSize: "14px",
  },
  jobOpeningCardContainer: {
    alignSelf: "center",
    maxWidth: "1000px",
    "@media (max-width:1070px)": {
      maxWidth: "750px",
    },
    "@media (max-width:850px)": {
      maxWidth: "500px",
    },
    "@media (max-width:600px)": {
      maxWidth: "250px ",
    },
  },
  jobOpeningCard: {
    display: "flex",
    flexDirection: "column",
    height: "180px",
    maxWidth: "230px",
    minWidth: "230px",
    margin: "0px 10px 0px 10px",
    backgroundColor: "white",
    borderRadius: "5px",
    borderBottom: "2px solid #dfdfdf",
    '& a:hover':{
      backgroundColor:"#b32457",
    }, 
  },
  jobRole:{
    height: "50px",
    textAlign: "center",
    padding: "20px 30px 0px 30px",
    fontWeight: "bold",
    fontSize: "16px",
    color: "#3e3f3f",
  },
  jobDetails:{
    height: "60px",
    textAlign: "center",
    padding: "0px 25px 0px 25px",
    overflow: "hidden",
    whiteSpace: "no-wrap",
    '& p':{
      fontSize: "13px",
      color: "#6f6f6f",
      margin: "0px 0px 0px 0px",
      lineHeight: "1.4", 
    }, 
  },
  jobApply: {
    display: "flex",
    height: "40px",
    fontSize: "15px",
    textAlign: "center",
  },
  jobApplyButton: {
    alignSelf: "center",
    margin: "0 auto",
    padding: "3px",
    backgroundColor:"#de2365",
    fontWeight: "bold",
    textDecoration: "none",
    color: "white !important",
    width: "80px",
    borderRadius: "2px",
  },
  noJobsMessage: {
    textAlign: "center", 
    paddingTop: "30px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "30px",
    marginRight: "30px",
    width: "1000px",
    color: "#3e3f3f",
    "@media (max-width:1040px)": {
      width: "100%",
      marginRight: "0px",
      flexDirection: "column",
    },
  },
  mainContentContainer: {
    display: "flex",
    flexDirection: "column",
    width: "750px",
    marginRight: "20px",
    "@media (max-width:1040px)": {
      width: "100%",
    },
  },
  sidebarContentContainer: {
    width: "230px",
    "@media (max-width:1040px)": {
      width: "100%",
    },
  },
  mainContent: {
    padding: "10px 25px 0px 25px",
    backgroundColor: "white",
    borderRadius: "5px",
    borderBottom: "2px solid #dfdfdf",
    "@media (max-width:600px)": {
      padding: "10px 15px 0px 15px",
    },
  },
  contentSection: {
    marginBottom: "40px",
    '& h3':{
      margin: "10px 0px 15px 0px",
      color: "#58c5a2",
      fontSize: "21px",
      lineHeight: "1.6",
    },
    '& h5':{
      margin: "10px 0px 10px 0px",
      color: "#5e5f5f",
      fontSize: "15px",
      lineHeight: "1.6",
    },
    '& img':{
      marginTop: "10px",
      position: "relative",
      width: "100% !important",
      height: "auto !important",
      objectFit: "cover",
      backgroundSize: "cover",
    },
    '& iframe':{
      position: "absolute",
      marginTop: "10px",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: "100%",
      height: "100%",
      border: "none",
    },
  },
  responsiveIframeContainer: {
    position: "relative",
    paddingTop: "56.25%",
    marginBottom: "35px"
  },
  sidebarContent: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 12px 5px 12px",
    backgroundColor: "white",
    borderRadius: "5px",
    borderBottom: "2px solid #dfdfdf",
    wordWrap: "break-word",
    '& p':{
      margin: "0px 0px 10px 0px",
      color: "#6f6f6f",
      fontSize: "13px",
      lineHeight: "1.6",
    }, 
  },
  loaderContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginLeft: "-40px",
  },
  bold: {
    fontWeight: "bold",
  },
  categoryLink: {
    color: "#0066cc !important",
    cursor: "pointer",
    textDecoration: "underline",
    '&:hover': {
      color: "#ff3399 !important",
    }
  }
});
  