import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

import MainHeader from 'components/MainHeader/mainHeader';
import MainFooter from 'components/MainFooter/mainFooter';

import JobBoard from '../JobBoard/jobBoard';
import About from '../AboutPage/aboutPage';
import PrivacyPolicy from '../PrivacyPolicyPage/privacyPolicyPage';
import TermsOfUse from '../TermsOfUsePage/termsOfUsePage';
import JobPage from '../JobPage/jobPage';
import styles from './styles';

class Home extends React.Component {
    render() { 
        const { classes } = this.props;
        return (  
          <div className={classes.root}>
            <div className={classes.wrapper}>
              <MainHeader />   
              <div className={classes.mainContent}>
                <Switch>
                  <Route exact path={`/`} component={JobBoard} />
                  <Route exact path={`/about`} component={About} />
                  <Route exact path={`/privacy-policy`} component={PrivacyPolicy} />
                  <Route exact path={`/terms-of-use`} component={TermsOfUse} />
                  <Route exact path={`/:slug`} component={JobPage} />
                  <Redirect from="*" to="/" />
                </Switch>
              </div>
              <MainFooter />
            </div>
          </div>       
        );
    }
}
 
export default withStyles(styles)(Home);