import React, { Component } from 'react';
import { withStyles } from "@material-ui/core/styles";
import parse from 'html-react-parser';

import styles from "./styles";

class InfoComponent extends Component {

  convertNodeToElement = (domNode) => {
    const { classes } = this.props;

    if (!!domNode.name && domNode.name === 'iframe'){
      return (
        <div className={classes.responsiveIframeContainer}>
          <iframe src={domNode.attribs.src} allowFullScreen={true}></iframe>
        </div>
      )
    }
  };

  render() {
    const { classes, data } = this.props;

    return (  
      <div>
        <div className={classes.container}>
          {!!data &&
          <div className={classes.content}>
            {!!data.title && 
              <div><h2>{data.title}</h2></div>
            }
            {!!data.tagline && 
              <div><h3>{data.tagline}</h3></div>
            }
            {!!data.article && parse(data.article, {
              replace: domNode => { return this.convertNodeToElement(domNode) }
            })}
          </div>
          }
        </div>
      </div>
    );
  }
}
 
export default withStyles(styles)(InfoComponent);