export default theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
    padding: "20px 0px 60px 0px",
    width: "730px",
    '@media (max-width:800px)': {
      width: "80%",
    },
    '& h1': {
      wordBreak: "break-word",
      fontSize: "48px",
      '@media (max-width:800px)': {
        fontSize: "26px",
      },
    },
    '& h2': {
      fontSize: "30px",
      '@media (max-width:800px)': {
        fontSize: "20px",
      },
    },
    '& h3': {
      fontSize: "20px",
      '@media (max-width:800px)': {
        fontSize: "18px",
      },
      margin: "20px 0px 3px 0px",
    },
    '& p': {
      fontSize: "18px",
      '@media (max-width:800px)': {
        fontSize: "16px",
      },
      lineHeight: "1.6",
      marginBottom: "30px",
    },
    '& a:link': {
      textDecoration: "underline semi-bold",
      color: "#0066cc",
    },
    '& a:visited':{
      color: "#0066cc"
    },
    '& a:hover': {
      color: "#ff3399",
      textDecoration: "underline",
    },
    '& a:active': {
      color: "#F06",
      textDecoration: "underline",
    },
    '& i': {
      fontWeight: "lighter",
    },
    '& img':{
      marginBottom: "10px",
      position: "relative",
      width: "100% !important",
      height: "auto !important",
      objectFit: "cover",
      backgroundSize: "cover",
    },
    '& iframe':{
      position: "absolute",
      marginBottom: "10px",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: "100%",
      height: "100%",
      border: "none",
    },
  },
  responsiveIframeContainer: {
    position: "relative",
    paddingTop: "56.25%",
    marginBottom: "35px"
  },
});

