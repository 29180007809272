import React from "react";
import Sticker from "./components/Sticker/sticker";
import WindowWidthSticker from "./components/windowWidthSticker";
import CompanyCard from "./components/CompanyCard/companyCard";
import PageLoader from "components/PageLoader/pageLoader";
import { Paper, InputBase } from "@material-ui/core";

import { Pagination } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import _ from "underscore";

import {
  getJobBoard,
  getValueTagCategories,
  getTeamsFiltered,
} from "utils/jobSearchService";

import styles from "./jobBoard.styles";
import clsx from "clsx";

class JobBoard extends React.Component {
  constructor(props) {
    super(props);
    this.myScrollRef = React.createRef();
    this.state = {
      selected: [],
      jobBoardData: {},
      categoriesData: {},
      teamsDataFiltered: {},
      searchInput: "",
      isHidden: true,
      isFiltered: false,
      isLoading: true,
      pagination: {
        name: "",
        page: 1,
      },
      itemsPerPage: 5,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    this.getCachedData();

    await getValueTagCategories().then((response) => {
      this.setState({ categoriesData: response.data }, () => {
        localStorage.setItem(
          "categoriesDataStorage",
          JSON.stringify(this.state.categoriesData)
        );
      });
    });

    await getJobBoard().then((response) => {
      this.setState({ jobBoardData: response.data, isLoading: false }, () => {
        localStorage.setItem(
          "jobboardDataStorage",
          JSON.stringify(this.state.jobBoardData)
        );
      });
    });
  };

  getCachedData = () => {
    !!localStorage.getItem("selectedValuesLocalStorage")
      ? this.setState(
          {
            selected: JSON.parse(
              localStorage.getItem("selectedValuesLocalStorage")
            ),
          },
          () => {
            this.getFilteredData();
            if (this.state.selected.length > 0) {
              this.setState({ isHidden: false });
            }
          }
        )
      : this.getFilteredData();

    if (!localStorage.getItem("jobboardDataStorage")) {
      getJobBoard().then((response) => {
        this.setState({ jobBoardData: response.data }, () => {
          localStorage.setItem(
            "jobboardDataStorage",
            JSON.stringify(this.state.jobBoardData)
          );
        });
      });
    } else if (!!localStorage.getItem("jobboardDataStorage")) {
      this.setState({
        jobBoardData: JSON.parse(localStorage.getItem("jobboardDataStorage")),
      });
    }

    if (!localStorage.getItem("categoriesDataStorage")) {
      getValueTagCategories().then((response) => {
        this.setState({ categoriesData: response.data }, () => {
          localStorage.setItem(
            "categoriesDataStorage",
            JSON.stringify(this.state.categoriesData)
          );
        });
      });
    } else if (!!localStorage.getItem("categoriesDataStorage")) {
      this.setState({
        categoriesData: JSON.parse(
          localStorage.getItem("categoriesDataStorage")
        ),
      });
    }
  };

  getFilteredData = () => {
    const { selected, searchInput } = this.state;

    let filterTag = "?";
    const searchInputFilter = "search=" + searchInput + "&";

    let valueTagSlugs = "";
    if (typeof selected[0] !== "undefined") {
      valueTagSlugs = "filters=";
      selected.map((value) => (valueTagSlugs += value.slug + ","));
      valueTagSlugs += "&";
    }
    filterTag += valueTagSlugs + searchInputFilter;
    this.updateTeamsFilteredState(filterTag);
  };

  updateTeamsFilteredState = (filterTag) => {
    getTeamsFiltered(
      filterTag,
      _.omit(this.state.pagination, (i) => !i)
    )
      .then((response) => {
        this.setState({ teamsDataFiltered: response.data }, () => {
          if (
            this.state.selected.length > 0 &&
            this.state.isFiltered === false
          ) {
            this.setState({ isFiltered: true });
          } else if (
            this.state.selected.length <= 0 &&
            this.state.isFiltered !== false
          ) {
            this.setState({ isFiltered: false });
          }
        });
      })
      .catch((error) => {});
  };

  handleClickDiscover = () => {
    this.setState({ isHidden: !this.state.isHidden });
  };

  onChangeInput = (input) => {
    this.setPageToFirst();
    this.setState({ searchInput: input }, () => {
      this.getFilteredData();
    });
  };

  newColorGenerator = (index) => {
    const categoryColors = [
      "#0066CC",
      "#3CA4EB",
      "#C65AEB",
      "#3366CC",
      "#CA75B0",
      "#EC6DEE",
    ];
    if (!!categoryColors[index]) {
      return categoryColors[index];
    } else {
      let valuesColor = [50, 90, 235];
      let r = valuesColor[0];
      let g = valuesColor[1];
      let b = valuesColor[2];
      const max = 205;

      if (index % 2 === 0) {
        r = r + index * 74;
        while (r > max) r = r - max;
      } else if (index % 3 === 0) {
        r = r + index * 54;
        while (r > max) r = r - max;
      } else {
        g = g + index * 74;
        while (g > max) g = g - max;
      }
      return `rgb(${r}, ${g}, ${b})`;
    }
  };

  isItemSelected = (item) => {
    const { selected } = this.state;
    const isSelected = _.contains(
      selected,
      _.findWhere(selected, {
        id: item.id,
      })
    );
    return isSelected;
  };

  onMouseOverValue = (event, value, color) => {
    const el = event.target;
    el.style.cursor = "pointer";
    if (!this.isItemSelected(value)) {
      el.style.border = `1px solid ${color}`;
      el.style.backgroundColor = [
        color.slice(0, color.length - 1),
        ",0.3",
        color.slice(color.length - 1),
      ].join("");
    }
  };

  onMouseOutValue = (event, value) => {
    const el = event.target;
    el.style.border = "";
    if (!this.isItemSelected(value)) el.style.backgroundColor = "";
  };

  handleClickValue = (event, value, color) => {
    const { selected } = this.state;
    const el = event.target;
    let newSelected = [];
    if (this.isItemSelected(value)) {
      newSelected = _.without(
        selected,
        _.findWhere(selected, {
          id: value.id,
        })
      );
      el.style.color = color;
      el.style.border = `1px solid ${color}`;
      el.style.backgroundColor = [
        color.slice(0, color.length - 1),
        ",0.3",
        color.slice(color.length - 1),
      ].join("");
    } else {
      newSelected = [value, ...selected];
      el.style.color = "white";
      el.style.backgroundColor = color;
    }

    this.setPageToFirst();
    this.setState(
      {
        selected: newSelected,
      },
      () => {
        this.getFilteredData();
      }
    );

    localStorage.setItem(
      "selectedValuesLocalStorage",
      JSON.stringify(newSelected)
    );
  };

  setPageToFirst = () => {
    if (this.state.pagination.page !== 1) {
      this.setState({ pagination: { name: "", page: 1 } }, () => {
        this.updateTeamsFilteredState("");
      });
    }
  };

  handlePageChange = (event, value) => {
    this.setState({ pagination: { name: "", page: value } }, () => {
      this.getFilteredData();
    });
    window.scrollTo(0, 0);
  };

  render() {
    const { classes } = this.props;
    const {
      jobBoardData,
      teamsDataFiltered,
      categoriesData,
      selected,
      isHidden,
      pagination,
      isLoading,
    } = this.state;

    if (!!isLoading) {
      return <div />;
    } else {
      return (
        <div className={classes.container}>
          <div
            className={clsx(classes.backgroundImage, {
              backgroundImageShrinked: isHidden,
            })}
            style={{
              backgroundImage: `url(${
                !!jobBoardData?.jobboard[0]?.background
                  ? jobBoardData?.jobboard[0]?.background.file
                  : ""
              })`,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              objectFit: "cover",
            }}
          />
          {jobBoardData.jobboard.length > 0 && (
            <div className={classes.jobBoardTitle}>
              <h1>{jobBoardData.jobboard[0].title}</h1>
              <h3>{jobBoardData.jobboard[0].subtitle}</h3>
              <Paper className={classes.search}>
                <InputBase
                  className={classes.searchInput}
                  placeholder="Search"
                  onChange={(event) => this.onChangeInput(event.target.value)}
                />
                <SearchIcon className={classes.searchIconButton} />
              </Paper>
              <p>OR</p>
              <button
                onClick={this.handleClickDiscover}
                className={classes.discoverButton}
              >
                Discover by Categories
              </button>
            </div>
          )}
          <div
            className={clsx(classes.valuesContainer, {
              [classes.valuesContainerHidden]: isHidden,
            })}
          >
            <div className={classes.valuesColumns}>
              {!!categoriesData.business_value_categories &&
                categoriesData.business_value_categories.map((category) => (
                  <div
                    key={category.id}
                    className={classes.values}
                    style={{ color: this.newColorGenerator(category.id - 1) }}
                  >
                    <h4 className={classes.categoryTitleContainer}>
                      <span className={classes.categoryTitle}>
                        {category.name.toUpperCase()}
                      </span>
                    </h4>
                    {category.business_values.map((value) => (
                      <div key={value.id}>
                        <div
                          className={`${classes.value}`}
                          style={
                            this.isItemSelected(value)
                              ? {
                                  color: "white",
                                  backgroundColor: this.newColorGenerator(
                                    category.id - 1
                                  ),
                                }
                              : null
                          }
                          onMouseEnter={(event) =>
                            this.onMouseOverValue(
                              event,
                              value,
                              this.newColorGenerator(category.id - 1)
                            )
                          }
                          onMouseOut={(event) =>
                            this.onMouseOutValue(event, value)
                          }
                          onClick={(event) =>
                            this.handleClickValue(
                              event,
                              value,
                              this.newColorGenerator(category.id - 1)
                            )
                          }
                        >
                          {value.name.toUpperCase()}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              <WindowWidthSticker>
                <Sticker
                  data={teamsDataFiltered}
                  inside={true}
                  selected={selected}
                  myScrollRef={this.myScrollRef}
                  isFiltered={this.state.isFiltered}
                />
              </WindowWidthSticker>
            </div>
            <WindowWidthSticker>
              <Sticker
                data={teamsDataFiltered}
                inside={false}
                selected={selected}
                myScrollRef={this.myScrollRef}
                isFiltered={this.state.isFiltered}
              />
            </WindowWidthSticker>
          </div>

          {!!teamsDataFiltered.teams ? (
            teamsDataFiltered.teams.count > 0 ? (
              <div
                className={classes.companyCardContainer}
                ref={this.myScrollRef}
              >
                {teamsDataFiltered.teams.results.map((company) => (
                  <CompanyCard company={company} key={company.id} />
                ))}
              </div>
            ) : (
              <div className={classes.searchNoResult}>
                No businesses match your search
              </div>
            )
          ) : (
            <div className={classes.loaderContainer}>
              <PageLoader />
            </div>
          )}
          <div className={classes.paginationContainer}>
            {teamsDataFiltered.teams &&
              teamsDataFiltered.teams.total_pages > 1 && (
                <Pagination
                  count={
                    teamsDataFiltered.teams &&
                    teamsDataFiltered.teams.total_pages
                  }
                  shape="rounded"
                  page={pagination.page}
                  onChange={this.handlePageChange}
                  hidePrevButton
                  hideNextButton
                />
              )}
          </div>
        </div>
      );
    }
  }
}
export default withRouter(withStyles(styles)(JobBoard));
