import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import styles from './companyCard.styles';

class CompanyCard extends Component {

  nextPath(path) {
    this.props.history.push(path);
  };

  ellipsizeText = (text, length) => {
    if (text.length > length)
      text = text.substr(0, length) + '...'
    return text;
  };

  displayCompanyValues = () => {
    const { company } = this.props;
    let companyValues = "";
    company.business_values.map((value, index) => 
      companyValues += (index ? ', ' : '') + value.business_value__name
    );
    return companyValues;
  };

  render() { 
    const { classes, company } = this.props;
    return (  
      <div 
        className={classes.companyCard}
        onClick={() => this.nextPath(`/${company.slug}`)}
      >
        <div className={classes.companyCardImageContainer}>
          <img alt='...' src={company.logo != null ? company.logo.file : ""} />
          <div className={classes.companyCardFooterContent}>
            <h2 title={company.title}>{this.ellipsizeText(company.title.toUpperCase(), 50)}</h2>
            <span title={this.displayCompanyValues()}>
              {this.ellipsizeText(this.displayCompanyValues(), 70)}
            </span>
          </div>
        </div>
        
        
      </div>
    );
  }
}
 
export default withRouter(withStyles(styles)(CompanyCard));
